<template>
  <calc-cart-item-row class="calc-cart-item">
    <template #before>
      <calc-cart-item-row-actions :actions="actions" />
    </template>

    <div class="calc-cart-item__main">
      <div class="calc-cart-item__head">
        <div class="calc-cart-item__service">
          {{ name }}
        </div>
      </div>
      <div class="calc-cart-item__sum">
        <calc-cart-item-price-and-summary
          :item="$props"
          @input="update"
        />
      </div>
      <div href="#" class="caption calc-cart-item__comment" v-if="note">
        <div class="calc-cart-item__comment-icon">
          <v-icon small>mdi-chat</v-icon>
        </div>
        <div class="calc-cart-item__comment-text">
          <span>{{ note }}</span>
        </div>
      </div>
    </div>

    <v-dialog v-model="dialog" :max-width="500">
      <calc-cart-item-details
        ref="details"
        :item="$props"
        @input="onDetailsUpdated"
      />
    </v-dialog>

    <calc-confirm
      v-bind="confirm"
      v-model="confirm"
      @[EVT_RESOLVED]="() => confirm = null"
    />
  </calc-cart-item-row>
</template>

<script>
import CalcCartItemPriceAndSummary from './CalcCartItemPriceAndSummary.vue'
import calcMixin from './calcMixin'
import CalcCartItemDetails from './CalcCartItemDetails.vue'
import CalcCartItemRowActions from './CalcCartItemRowActions.vue'
import CalcCartItemRow from './CalcCartItemRow.vue'
import CalcConfirm, { EVT_RESOLVED } from './CalcConfirm.vue'

export default {
  mixins: [calcMixin],
  components: {
    CalcCartItemRow,
    CalcCartItemRowActions,
    CalcCartItemDetails,
    CalcCartItemPriceAndSummary,
    CalcConfirm,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    uuid: {
      type: String,
      required: true
    },
    name: {
      type: String
    },
    price: {
      type: Number
    },
    currency: {
      type: String
    },
    surcharge: {
      type: Number
    },
    group: {
      type: String
    },
    amount: {
      type: Number
    },
    summary: {
      type: Object,
    },
    note: {
      type: String
    }
  },
  data () {
    return {
      EVT_RESOLVED,
      dialog: false,
      confirm: false
    }
  },
  watch: {
    dialog (value) {
      if (value) {
        this.$nextTick(() => this.$refs.details.reset())
      }
    }
  },
  computed: {
    actions () {
      return [
        {
          $key: 'edit',
          icon: 'mdi-pencil',
          text: 'Edit item',
          on: {
            click: () => this.dialog = true
          }
        },
        {
          $key: 'remove',
          icon: 'mdi-delete',
          text: 'Remove item',
          $danger: true,
          on: {
            click: () => this.remove()
          }
        },
      ]
    }
  },
  methods: {
    remove () {
      this.confirm = {
        title: this.$t('calc.cartItemRemoveConfirmationText', { name: this.name }),
        onConfirm: () => this.removeItem(this.uuid)
      }
    },
    update (payload) {
      this.updateItem({ ...this.$props, ...payload })
    },
    onDetailsUpdated (payload) {
      this.update(payload)
      this.dialog = false
    }
  },
}
</script>

<style lang="scss" scoped>
.calc-cart-item {
  padding: 8px 12px 8px 4px;

}

.calc-cart-item__main {
  flex: 1 1 auto;
}

.calc-cart-item__head {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.calc-cart-item__comment {
  opacity: 0.7;
  display: flex;
  grid-gap: 0.5em;
}
</style>
