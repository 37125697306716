export default class OnlyPriceFilters {
  constructor (options) {
    const {
      search,
      clinicId,
      specialityId,
      specialityIds,
      serviceIds,
      doctorId,
      categories,
      tenant,
    } = options || {}
    return {
      search,
      clinicId,
      specialityId,
      specialityIds,
      serviceIds,
      doctorId,
      categories,
      tenant,
    }
  }
}
