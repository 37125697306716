<template>
  <div class="calc-cart-item-price-summary">
    <div class="calc-cart-item-price-summary__price">
      <calc-total-price
        :price="item.price"
        :surcharge="item.surcharge"
        :currency="item.currency"
      />

      <v-amount-counter
        :min="1"
        @input="(amount) => update({ amount })"
        :value="item.amount"
        v-slot="{ value }"
      >
        &times;{{ value }}
      </v-amount-counter>
    </div>

    <calc-total-price
      v-if="item.summary"
      :currency="item.currency"
      v-bind="item.summary"
      class="text-h5 calc-cart-item-price-summary__summary"
    />
  </div>
</template>

<script>
import calcMixin from './calcMixin'
import CalcTotalPrice from './CalcTotalPrice.vue'
import VAmountCounter from '@/components/VAmountCounter.vue'

export default {
  components: {
    VAmountCounter,
    CalcTotalPrice
  },
  mixins: [calcMixin],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    update (payload) {
      this.$emit('input', { ...this.item, ...payload })
    }
  }
}
</script>

<style lang="scss" scoped>
.calc-cart-item-price-summary {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.calc-cart-item-price-summary__price {
  display: inline-flex;
  align-items: center;
}
</style>
