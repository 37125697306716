<template>
  <div class="pagination-template">
    <slot
      v-if="error"
      name="error"
      v-bind="$props"
    >
      {{ error }}
    </slot>

    <template v-else-if="items.length">
      <slot
        name="items"
        v-bind="$props"
      />
      <div v-if="!end" class="pagination-template__next">
        <slot name="next" v-bind="$props">
          <VBtn view="secondary" @click="next" :disabled="pending">
            {{ $t('buttons.loadMore') }}
          </VBtn>
        </slot>
      </div>
    </template>

    <slot
      v-else-if="pending"
      name="loading"
      v-bind="$props"
    >
      {{ $t('notes.loading') }}
    </slot>

    <slot
      v-else
      name="notFound"
      v-bind="$props"
    >
      {{ $t('notes.notFound') }}
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: [Error, Object]
    },
    pending: {
      type: Boolean,
      defalut: false
    },
    next: {
      type: Function,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    end: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-template__next {
  margin-top: 40px;
  text-align: center;
}
</style>
