<template>
  <div class="calc-search-item">
    <div class="calc-search-item__head">
      <div class="calc-search-item__service title">
        {{ name }}
      </div>
      <div class="calc-search-item__actions">
        <v-btn color="primary" success @click="() => addItem(this.$props)">
          {{ $t('calc.addToCartAction') }}
        </v-btn>
      </div>
    </div>
    <div class="calc-search-item__price">
      <calc-total-price
        :price="price"
        :surcharge="surcharge"
        :currency="currency"
      />
    </div>

    <a href="#" @click.prevent.stop="toggleDetails">
      <span>{{ $t(`buttons.${details ? 'hide' : 'details'}`) }}</span>
      <v-icon>{{ details ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
    </a>
    <div v-if="details" style="margin-top: 1em">
      <div class="calc-search-item__location" v-if="clinicsList">
        <v-icon small>mdi-map-marker</v-icon> {{ clinicsList }}
      </div>

      <div class="calc-search-item__doctors" v-if="doctorsList" style="margin-top: 1em">
        <v-icon small>mdi-account</v-icon> {{ doctorsList }}
      </div>
    </div>
  </div>
</template>

<script>
import calcMixin from './calcMixin'
import CalcTotalPrice from './CalcTotalPrice.vue'

export default {
  components: {
    CalcTotalPrice
  },
  mixins: [calcMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String
    },
    clinics: {
      type: Array
    },
    doctors: {
      type: Array
    },
    price: {
      type: Number
    },
    currency: {
      type: String
    },
    surcharge: {
      type: Number
    },
  },
  data () {
    return {
      details: false
    }
  },
  computed: {
    clinicsList () {
      return this.clinics.map(({ shortName }) => shortName).join(', ')
    },
    doctorsList () {
      return this.doctors.map(({ displayName }) => displayName).join(', ')
    }
  },
  methods: {
    toggleDetails () {
      this.details = !this.details
    }
  }
}
</script>

<style lang="scss" scoped>
.calc-search-item__head {
  display: flex;
  grid-gap: 20px;
  justify-content: space-between;
  margin-bottom: 0.5em
}

</style>
