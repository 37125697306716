<template>
  <div class="amount-counter">
    <v-btn @click="dec" icon x-small :disabled="!canDec" class="amount-counter__control">
      <v-icon x-small>mdi-minus</v-icon>
    </v-btn>
    <div>
      <slot v-bind="{ value: innerValue }">
        {{ innerValue }}
      </slot>
    </div>
    <v-btn @click="inc" icon x-small :disabled="!canInc" class="amount-counter__control">
      <v-icon x-small>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: Infinity
    }
  },
  data () {
    return {
      innerValue: this.value
    }
  },
  watch: {
    value (value) {
      this.innerValue = value
    }
  },
  computed: {
    canInc () {
      return this.innerValue < this.max
    },
    canDec () {
      return this.innerValue > this.min
    },
  },
  methods: {
    inc () {
      if (!this.canInc) return
      this.innerValue++
      this.$emit('input', this.innerValue)
    },
    dec () {
      if (!this.canDec) return
      this.innerValue--
      this.$emit('input', this.innerValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.amount-counter {
  display: inline-flex;
  align-items: center;
}

.amount-counter__control {
  opacity: 0;
  .amount-counter:hover & {
    opacity: 1;
  }
}
</style>
