<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon >
        <v-icon large>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list class="calc-cart-item__actions">
      <v-list-item
        link
        v-for="action in actions" :key="action.$key"
        v-on="action.on"
      >
          <v-list-item-title :class="action.$danger ? 'red--text' : ''" v-if="action.text">
            <v-icon :class="action.$danger ? 'red--text' : ''" v-if="action.icon">{{ action.icon }}</v-icon>
            <span class="ml-2">
              {{ action.text }}
            </span>
          </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    actions: {
      type: Array,
      required: true
    }
  }
}
</script>
