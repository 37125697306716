<template>
  <div class="calc-doctor">
    <v-img
      class="calc-doctor__image"
      :src="doctor.avatar ? doctor.avatar.smallSquareUrl : null"
    />
    <span>
      {{ doctor.fullName }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    doctor: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.calc-doctor {
  display: flex;
  align-items: center;
  grid-gap: 8px
}
.calc-doctor__image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
</style>
