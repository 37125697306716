<template>
  <div
    class="calc-cart-group"
    :class="active ? 'calc-cart-group--active' : ''"
  >
    <calc-cart-item-row
      class="calc-cart-group__head"
      @click.native="() => selectGroup(uuid)"
    >
      <template #before>
        <order-controls
          style="width: 24px"
          @[EVT_CLICK]="(e) => $emit(EVT_REORDER, e)"
        />
      </template>
      <template #after>
        <calc-cart-item-row-actions :actions="actions" />
      </template>
      <div class="calc-cart-group__main">
        <div class="calc-cart-group__name">
          <v-text-field
            hide-details
            dense
            :value="name"
            @input="(name) => updateGroup({ ...$props, name })"
          />
        </div>
        <calc-total-price
          v-if="items.length"
          :currency="currency"
          v-bind="summary"
          class="calc-cart-group__summary text-h5"
        />
      </div>
    </calc-cart-item-row>

    <transition name="fade" v-if="items.length">
      <div class="calc-cart-group__body">
        <transition-group tag="div" name="fade" class="calc-cart-group__items">
          <calc-cart-item
            v-for="item in items"
            :key="item.uuid"
            v-bind="item"
          />
        </transition-group>
      </div>
    </transition>

    <calc-confirm
      v-bind="confirm"
      v-model="confirm"
      @[EVT_RESOLVED]="() => confirm = null"
    />

  </div>
</template>

<script>
import CalcCartItemRow from './CalcCartItemRow.vue'
import CalcCartItem from './CalcCartItem.vue'
import calcMixin from './calcMixin'
import CalcTotalPrice from './CalcTotalPrice.vue'
import CalcCartItemRowActions from './CalcCartItemRowActions.vue'
import CalcConfirm, { EVT_RESOLVED } from './CalcConfirm.vue'
import OrderControls, { EVT_CLICK } from '@/components/OrderControls.vue'
export const EVT_REORDER = 'reorder'

export default {
  mixins: [calcMixin],
  components: {
    OrderControls,
    CalcConfirm,
    CalcCartItemRow,
    CalcCartItemRowActions,
    CalcCartItem,
    CalcTotalPrice,
  },
  props: {
    active: {
      type: Boolean,
    },
    uuid: {
      type: String
    },
    name: {
      type: String
    },
    items: {
      type: Array
    },
    summary: {
      type: Object
    },
    currency: {
      type: String
    }
  },
  data () {
    return {
      EVT_REORDER,
      EVT_CLICK,
      EVT_RESOLVED,
      confirm: false
    }
  },
  computed: {
    actions () {
      return [
        {
          $key: 'clear',
          text: 'Clear',
          icon: 'mdi-broom',
          on: {
            click: () => this.clear()
          }
        },
        {
          $key: 'remove',
          text: 'Remove',
          icon: 'mdi-close',
          $danger: true,
          on: {
            click: () => this.remove()
          }
        },
      ]
    }
  },
  methods: {
    remove () {
      this.confirm = {
        title: this.$t('calc.cartGroupRemoveConfirmationText', { name: this.name }),
        onConfirm: () => this.removeGroup(this.uuid)
      }
    },
    clear () {
      this.confirm = {
        title: this.$t('calc.cartGroupClearConfirmationText', { name: this.name }),
        onConfirm: () => this.clearGroup(this.uuid)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.calc-cart-group {
  border-radius: 12px;
  border: 2px solid transparent
}
.calc-cart-group--active {
  border-color: #1976d2;
  background: rgba(#1976d2, 0.05);
}
.calc-cart-group__head {
  padding: 12px 4px;
}
.calc-cart-group__main {
  display: flex;
  justify-content: space-between;
  grid-gap: 1em;
}
.calc-cart-group__name {
  flex: 1 1 auto;
}
.calc-cart-group__summary {
  flex: 0 0 auto;
}

.calc-cart-group__body {
  border-top: 2px solid;
}

.calc-cart-group__items {
  & > * {
    border-top: 1px solid #ccc;
    &:first-child {
      border-top: 0;
    }
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave {
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
}

.fade-enter-active {
  transition: 1s opacity
}
.fade-leave-active {
  transition: 0.3s opacity
}
</style>
