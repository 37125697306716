<template>
  <div class="calc-db">
    <calc-person-select v-model="doctor">
      <template #person>
        <calc-doctor :doctor="doctor" />
      </template>
      <template #control>
        <doctor-select v-model="doctor" hide-details />
      </template>
    </calc-person-select>

    <calc-person-select v-model="patient">
      <template #person>
        <span style="opacity: 0.7">{{ $t('form.labels.patient') }}: </span>
        <span>{{ patient.fullName }}</span>
        <span style="opacity: 0.7" class="ml-2">{{ patient.recordId }}</span>
      </template>
      <template #control>
        <patient-select v-model="patient" hide-details />
      </template>
    </calc-person-select>

    <div class="calc-db__actions">
      <v-btn
        v-for="btn in buttons"
        :key="btn.$key"
        v-bind="btn.bind"
        v-on="btn.on"
        rounded
        x-small
        fab
        :title="btn.label"
      >
        <v-icon>{{ btn.icon }}</v-icon>
      </v-btn>
      <div
        v-if="cart.length"
        class="calc-db__sum"
      >
        <span>{{ $t('calc.summaryLabel') }}:</span>
        <calc-total-price
          key="summary"
          class="text-h5"
          v-bind="cartSummary"
        />
      </div>
    </div>

    <calc-confirm
      v-bind="confirm"
      v-model="confirm"
      @[EVT_RESOLVED]="() => confirm = null"
    />

  </div>
</template>

<script>
import DoctorSelect from '@/components/Doctor/DoctorSelect.vue'
import PatientSelect from '@/components/Patient/PatientSelect.vue'
import calcMixin from './calcMixin'
import CalcPersonSelect from './CalcPersonSelect.vue'
import CalcDoctor from './CalcDoctor.vue'
import CalcTotalPrice from './CalcTotalPrice.vue'
import CalcConfirm, { EVT_RESOLVED } from './CalcConfirm.vue'

export default {
  mixins: [calcMixin],
  components: {
    CalcTotalPrice,
    CalcDoctor,
    CalcPersonSelect,
    DoctorSelect,
    PatientSelect,
    CalcConfirm
  },
  data () {
    return {
      confirm: null,
      EVT_RESOLVED,
      doctor: null,
      patient: null
    }
  },
  computed: {
    buttons () {
      const isEmpty = !this.doctor || !this.patient || !this.cart.length
      const { doctor, patient } = this
      return [
        {
          $key: 'group',
          label: this.$t('calc.dashboard.addGroupAction'),
          icon: 'mdi-plus',
          bind: {
          },
          on: {
            click: () => this.addGroup({ name: this.$t('calc.newGroupName') })
          },
        },
        {
          $key: 'print',
          label: this.$t('calc.dashboard.printAction'),
          icon: 'mdi-printer-pos',
          bind: {
            disabled: isEmpty
          },
          on: {
            click: () => this.print({ doctor, patient })
          },
        },
        {
          $key: 'attach',
          label: this.$t('calc.dashboard.attachAction'),
          icon: 'mdi-paperclip',
          bind: {
            disabled: isEmpty
          },
          on: {
            click: async () => {
              await this.attach({ doctor, patient })
              alert(this.$t('calc.documentAttachedMessage'))
            }
          },
        },
        {
          $key: 'clear',
          label: this.$t('calc.dashboard.resetAction'),
          icon: 'mdi-close',
          bind: {
            disabled: !this.groups.length
          },
          on: {
            click: () => {
              this.confirm = {
                title: this.$t('calc.dashboard.resetConfirmationText'),
                onConfirm: () => this.reset()
              }
            }
          },
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.calc-db__actions {
  display: flex;
  grid-gap: 12px;
  margin-top: 32px;
  align-items: center;
}

.calc-db__doctor {
  display: flex;
  & > * {
    flex: 0 0 auto;
  }
}

.calc-db__sum {
  margin-left: auto;
  margin-right: 0;
  display: flex;
  align-items: center;
  grid-gap: 0.25em;
}
</style>
