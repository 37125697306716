import isEqual from "lodash/isEqual"
import { createRequest } from '@/utils/requestsFactory'

const FIRST_PAGE = 1

const parsePage = (page) => Number(page) ? Number(page) : FIRST_PAGE

export default {
  props: {
    page: {
      type: [Number, String],
      default: FIRST_PAGE
    },
    limit: {
      type: [Number, String],
    },
  },
  model: {
    prop: 'page',
    event: 'input'
  },
  data () {
    return {
      currentPage: parsePage(this.page),
      end: false,
      items: [],
      fetch: createRequest(async () => {
        if (this.currentPage === 1) {
          this.items = []
        }
        const { items, page, pages } = await this.load({
          page: this.currentPage,
          ...this.params
        })
        this.items = this.items.concat(items)
        this.end = page >= pages
      })
    }
  },
  created () {
    return this.fetch.run()
  },
  watch: {
    page (page) {
      this.currentPage = parsePage(page)
      this.fetch.run()
    },
    params: {
      deep: true,
      handler (params, oldParams) {
        if (isEqual(params, oldParams)) return
        this.currentPage = FIRST_PAGE
        this.fetch.run()
      }
    }
  },
  computed: {
    scope () {
      const { error, pending } = this.fetch
      const { items, end, currentPage: page, next } = this
      return {
        items,
        error,
        pending,
        end,
        page,
        next,
      }
    },
    params () {
      const { page, ...params } = this.$props
      return params
    }
  },
  methods: {
    next () {
      this.currentPage = this.currentPage + 1
      this.fetch.run()
      this.$emit('input', this.currentPage)
    }
  }
}
