<template>
  <div class="calc-cart">
    <items-order-provider
      :items="cartItems"
      @input="onInput"
      v-if="cartItems.length"
      v-slot="scope"
    >
    <!-- <vue-draggable :disabled="cartItems.length < 2" v-if="cartItems.length" :value="cartItems" @input="onInput"> -->
      <transition-group tag="div" class="calc-cart__groups" name="fade">
        <calc-cart-group
          v-for="item, idx in cartItems"
          :key="item.uuid"
          @[EVT_REORDER]="(dir) => reorder(dir, scope, idx)"
          v-bind="{
            ...item,
            active: item.uuid === currentGroup
          }"
        />
      </transition-group>
    <!-- </vue-draggable> -->
    </items-order-provider>
    <div v-else>
      {{ $t('calc.emptyCart') }}
    </div>
  </div>
</template>

<script>
import VueDraggable from 'vuedraggable'
import calcMixin from './calcMixin'
import CalcCartGroup, { EVT_REORDER } from './CalcCartGroup.vue'
import ItemsOrderProvider from '@/components/ItemsOrderProvider.vue'

export default {
  components: {
    // VueDraggable,
    CalcCartGroup,
    ItemsOrderProvider
  },
  mixins: [calcMixin],
  data () {
    return {
      EVT_REORDER,
    }
  },
  methods: {
    onInput (list) {
      const order = list.map(({ uuid }) => uuid)
      this.reorderGroups(order)
    },
    reorder (dir, { next, prev }, idx) {
      const action = {
        up: prev,
        down: next,
      }[dir]
      return action(idx)
    }
  }
}
</script>

<style lang="scss" scoped>
.calc-cart__groups {
  & > * {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
