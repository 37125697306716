<template>
  <AutocompleteAsync
    v-bind="attrs"
    v-on="$listeners"
    class="patient-select"
  >
    <template #item="scope">
      <div class="patient-select__item">
        <div class="patient-select__item-name">
          {{ scope.item.fullName }}
        </div>
        <div class="patient-select__item-id" v-if="scope.item.recordId">
          {{ scope.item.recordId }}
        </div>
      </div>
    </template>
  </AutocompleteAsync>
</template>

<script>
import { getPatientFullName } from '@/utils/format'
import AutocompleteAsync from '../Cart/AutocompleteAsync.vue'
import ehrs from '../../services/ehrs'

export default {
  components: {
    AutocompleteAsync
  },
  computed: {
    attrs () {
      return {
        ...this.$attrs,
        name: "doctor",
        placeholder: this.$t('form.labels.patient'),
        itemText: 'fullName',
        itemValue: 'id',
        load: (...args) => this.load(...args),
        filter: (item, queryText) => {
          const search = queryText.toLowerCase()
          if (item.fullName.toLowerCase().indexOf(search) > -1) return true
          if (String(item.recordId) === queryText) return true
          return false
        }
      }
    }
  },
  methods: {
    async load (params) {
      const items = await ehrs.getAll(params)
      return items.map((item) => ({
        ...item,
        fullName: getPatientFullName(item)
      }))
    },
  }
}
</script>

<style lang="scss" scoped>
.patient-select__item {
  display: flex;
  grid-gap: 0.5em;
  justify-content: space-between;
  width: 100%;
}
.patient-select__item-name {
  flex: 1 1 100%;
}
.patient-select__item-id {
  flex: 0 0 auto;
  opacity: 0.55;
}
</style>
