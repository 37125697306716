<template>
  <v-dialog
    v-bind="$attrs"
    persistent
    :max-width="550"
  >
    <div>
      <div class="text-h5 mb-4" v-if="title">
        {{ title }}
      </div>
      <div class="mb-4" v-html="text" v-if="text" />
      <v-row>
        <v-col v-for="item in items" :key="item.$key">
          <v-btn block :color="item.color" v-on="item.on">
            {{ item.text }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-dialog>
</template>

<script>
export const EVT_RESOLVED = 'resolved'
export const EVT_CONFIRMED = 'confirmed'
export const EVT_CANCELED = 'canceled'

export default {
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    onConfirm: {
      type: Function
    },
    onCancel: {
      type: Function
    }
  },
  computed: {
    items () {
      return [
        {
          $key: 'yes',
          text: this.$t('buttons.confirm'),
          on: {
            click: async () => {
              if (this.onConfirm) {
                await this.onConfirm()
              }
              this.$emit(EVT_CONFIRMED)
              this.$emit(EVT_RESOLVED, true)
            }
          }
        },
        {
          $key: 'no',
          text: this.$t('buttons.cancel'),
          on: {
            click: async () => {
              if (this.onCancel) {
                await this.onCancel()
              }
              this.$emit(EVT_CANCELED)
              this.$emit(EVT_RESOLVED, false)
            }
          },
          color: 'primary'
        }
      ]
    }
  }
}
</script>
