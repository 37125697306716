<template>
  <div class="order-controls">
    <v-btn
      :elevation="0"
      fab
      :color="color"
      type="button"
      class="order-controls__button"
      v-for="item in items"
      :key="item.$key"
      @click="item.click"
    >
      <v-icon>
        {{ item.icon }}
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export const EVT_UP = 'up'
export const EVT_DOWN = 'down'
export const EVT_CLICK = 'click'
export const DIR_UP = 'up'
export const DIR_DOWN = 'down'

export default {
  props: {
    color: {
      type: String,
    }
  },
  data () {
    return {
      items: [
        {
          $key: 'up',
          click: () => {
            this.$emit(EVT_UP)
            this.$emit(EVT_CLICK, DIR_UP)
          },
          icon: 'mdi-menu-up'
        },
        {
          $key: 'down',
          click: () => {
            this.$emit(EVT_DOWN)
            this.$emit(EVT_CLICK, DIR_DOWN)
          },
          icon: 'mdi-menu-down'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.order-controls__button {
  height: 20px;
  width: 100%;
  display: flex;
  &:first-child {
    border-radius: 8px 8px 0 0;
  }
  &:last-child {
    border-radius: 0 0 8px 8px;
  }
}
</style>
