<template>
  <PaginationTemplate v-bind="scope">
    <template
      v-for="(_, name) in $scopedSlots"
      :slot="name"
      slot-scope="slotData"
    >
        <slot
          :name="name"
          v-bind="slotData"
        />
    </template>
  </PaginationTemplate>
</template>

<script>
import paginationMixin from '@/mixins/paginationMixin'
import service from '@/services/service'
import PaginationTemplate from '@/components/Pagination/PaginationTemplate.vue'

export default {
  components: {
    PaginationTemplate
  },
  mixins: [paginationMixin],
  props: {
    search: {
      type: String,
    },
    clinicId: {
      type: String,
    },
    specialityId: {
      type: String,
    },
    specialityIds: {
      type: Array,
    },
    serviceIds: {
      type: Array,
    },
    doctorId: {
      type: String
    },
    categories: {
      type: Array,
    },
    tenant: {
      type: String,
    }
  },
  methods: {
    load (params) {
      return service.getOnlyPrice(params)
    }
  }
}
</script>
