<script>
import tenantService from '@/services/tenant'
import { createRequest } from '@/utils/requestsFactory'
import ErrorDisplay from '@/components/Error/ErrorDisplay.vue'

export default {
  props: {

  },
  data () {
    return {
      req: createRequest(() => tenantService.tenant()),
    }
  },
  async created () {
    this.tenant = await this.req.run()
  },
  computed: {
    scope () {
      const { error, pending, data: tenant } = this.req
      const tenants = tenant ? [
        tenant,
        ...tenant.externalTenants
      ] : []
      return {
        pending,
        error,
        tenant,
        tenants
      }
    },
  },
  render (h) {
    const {
      pending: pendingSlot,
      error: errorSlot,
      default: defaultSlot,
    } = this.$scopedSlots
    if (this.req.pending) {
      return pendingSlot ? pendingSlot(this.scope) : h('span', 'Load tenant...')
    } else if (this.req.error) {
      return errorSlot ? errorSlot(this.scope) : h(ErrorDisplay, { props: { error: this.req.error } })
    } else if (this.req.data) {
      return defaultSlot ? defaultSlot(this.scope) : null
    } else {
      return null
    }
  }
}
</script>
