import { createNamespacedHelpers } from 'vuex'
const { mapActions, mapState, mapGetters } = createNamespacedHelpers('calculator')

export default {
  computed: {
    ...mapState([
      'cart',
      'groups',
      'currentGroup',
    ]),
    ...mapGetters([
      'cartItems',
      'cartSummary',
    ])
  },
  methods: {
    ...mapActions([
      'configure',
      'addItem',
      'updateItem',
      'removeItem',
      'addGroup',
      'removeGroup',
      'clearGroup',
      'updateGroup',
      'reorderGroups',
      'selectGroup',
      'print',
      'attach',
      'reset',
    ])

  },
}
