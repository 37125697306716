<template>
  <div class="cart-total-price">
    <v-price
      :value="price"
      :currency="currency"
      class="cart-total-price__main"
    />
  </div>
</template>

<script>
export default {
  props: {
    price: {
      type: Number
    },
    currency: {
      type: String
    },
  }
}
</script>

<style lang="scss" scoped>
.cart-total-price {
  display: inline-flex;
  align-items: center;
  grid-gap: 0.25em;
}
.cart-total-price__surcharge {

  opacity: 0.55;
  font-size: 0.8em;
}
</style>
