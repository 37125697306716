<template>
  <div class="tenant-select">
    <div class="title">
      {{ $t('tenant.selectTenantInvitation') }}
    </div>

    <v-row>
      <v-col v-for="tenant in tenants" :key="tenant.name">
        <v-card @click="() => $emit('input', tenant.name)">
          <v-card-text>
            <span class="title">
              {{ tenant.settings.shortTitleWithPlace }}
            </span>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    tenants: {
      type: Array,
      required: true
    },
    value: {
      type: String
    }
  }
}
</script>
