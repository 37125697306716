<template>
  <div class="calc-cart-item-details">
    <div class="text-h5 mb-1">
      {{ item.name }}
    </div>
    <div class="mb-4">
      <div class="calc-cart-item-details__price">
        <calc-total-price v-bind="item" />
        <div>&times;{{ item.amount }}</div>
        <span>=</span>
        <calc-total-price
          :currency="item.currency"
          class="title"
          v-bind="item.summary"
        />
      </div>
    </div>
    <v-textarea
      autofocus
      v-model.trim="note"
      hide-details
      dense
      outlined
      label="Add your comment"
    />
    <div class="mt-4">
      <v-btn color="primary" @click="$emit('input', { note })">
        Save
      </v-btn>
    </div>
  </div>
</template>

<script>
import CalcCartItemPriceAndSummary from './CalcCartItemPriceAndSummary.vue'
import CalcTotalPrice from './CalcTotalPrice.vue'

export default {
  components: {
    CalcTotalPrice,
    CalcCartItemPriceAndSummary,
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      note: this.item.note
    }
  },
  methods: {
    reset () {
      this.note = this.item.note
    }
  }
}
</script>

<style lang="scss" scoped>
.calc-cart-item-details__price {
  display: flex;
  grid-gap: 0.5em;
  align-items: center;
}
</style>
