<template>
  <div class="calc-search">
    <v-text-field
      autofocus
      autocomplete="off"
      :label="$t('form.labels.service')"
      name="service"
      :value="filters.search"
      :debounce="500"
      hide-details
      @input="(search) => filter({ search })"
    />

    <div class="d-flex align-center mt-4" v-if="doctor">
      <div>
        {{ $t('form.labels.doctor') }}: {{ doctor.fullName }}
      </div>
      <div>
        <v-btn icon @click="() => setDoctor(null)"><v-icon>mdi-close</v-icon></v-btn>
      </div>
    </div>
    <doctor-select
      v-else
      :placeholder="$t('calc.search.doctorFilterLabel')"
      hide-details
      @input="(e) => setDoctor(e)"
    />

    <div class="calc-search__results mt-8">
      <OnlyPricePagination
        v-if="filters.search"
        v-bind="{ ...filters, specialityIds }"
        @input="(page) => filter({ page })"
      >
        <template #items="{ items }">
          <div class="calc-search__items">
            <CalcSearchItem v-bind="item" v-for="item, idx in map(items)" :key="idx">
              {{ item.name }}: {{ item.price }}
            </CalcSearchItem>
          </div>
        </template>
      </OnlyPricePagination>

      <div v-else>
        {{ $t('calc.searchInvitation') }}
      </div>
    </div>
  </div>
</template>

<script>
import VTextField from '@/components/VTextField.vue'
import OnlyPricePagination from '@/components/OnlyPrice/OnlyPricePagination.vue'
import { map } from '@/mappers/OnlyPriceMapper'
import CalcSearchItem from './CalcSearchItem.vue'
import DoctorSelect from '../Doctor/DoctorSelect.vue'
import OnlyPriceFilters from '@/models/OnlyPriceFilters'

export default {
  components: {
    VTextField,
    OnlyPricePagination,
    CalcSearchItem,
    DoctorSelect,
  },
  props: {
    specialityIds: {
      type: Array,
      default: () => ([]),
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    filter: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      doctor: null
    }
  },
  methods: {
    map,
    setDoctor (doctor) {
      this.doctor = doctor
      this.filter({ doctorId: doctor?.id })
    }
  }
}
</script>

<style lang="scss" scoped>
.calc-search__items {
  & > * {
    margin-bottom: 32px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
