<template>
  <query-sync v-slot="{ query, push }">
    <div class="calculator-page">
      <tenant-provider v-slot="{ tenants }">
        <div v-if="query.tenant">
          <a href="#" @click.prevent="() => push({ ...query, speciality: null, tenant: null })">
            {{ $t('buttons.back') }}
          </a>
          <calc
            :filters="new CalculatorQueryFilters(query)"
            @[EVT_FILTER]="(payload) => push({ ...query, ...new CalculatorQueryFilters(payload) })"
          />
        </div>
        <div v-else>
          <div class="text--h2">
            {{ $t('calc.title') }}
          </div>
          <tenant-select
            :tenants="tenants"
            @input="(tenant) => push({ ...query, tenant })"
          />
        </div>
      </tenant-provider>
    </div>
  </query-sync>
</template>

<script>
import QuerySync from '@/components/QuerySync.vue'
import TenantProvider from '@/components/Tenant/TenantProvider.vue'
import TenantSelect from '@/components/Tenant/TenantSelect.vue'
import Calc, { EVT_FILTER } from '@/components/Calc/Calc.vue'

class CalculatorQueryFilters {
  constructor (options) {
    const {
      tenant,
      search
    } = options || {}
    return {
      tenant,
      search
    }
  }
}

export default {
  components: {
    QuerySync,
    TenantProvider,
    TenantSelect,
    Calc,
  },
  data () {
    return {
      CalculatorQueryFilters,
      EVT_FILTER
    }
  }
}
</script>
