import groupBy from 'lodash/groupBy'
import uniqBy from 'lodash/uniqBy'

const keyFields = ['price,', 'currency', 'surcharge']
const makeKey = (item) => keyFields.reduce((acc, key) => acc + item[key], '')

function reduceDoctors (prices) {
  const all = prices.reduce((acc, { doctor }) => {
    if (doctor) acc.push(doctor)
    return acc
  }, [])
  return uniqBy(all, 'id')
}

function reduceClinics (prices) {
  const all = prices.reduce((acc, { clinic }) => {
    if (clinic) acc.push(clinic)
    return acc
  }, [])
  return uniqBy(all, 'id')
}

export function map (items = []) {
  return items.reduce((acc, { service, prices }) => {
    const groupedByPrice = groupBy(prices, (item) => {
      return makeKey(item)
    })

    const services = Object.values(groupedByPrice).map((items) => {
      const {
        value: price,
        currency,
        surcharge
      } = items[0]
      const a = {
        name: service.name,
        price,
        currency,
        doctors: reduceDoctors(items),
        clinics: reduceClinics(items),
        surcharge,
      }
      a.id = service.name + makeKey(a)
      return a
    })
    return [ ...acc, ...services ]
  }, [])
}
