<template>
  <v-text-field
    v-bind="$attrs"
    v-on="{ ...$listeners, input }"
  >
    <template
      v-for="(_, name) in $scopedSlots"
      :slot="name"
      slot-scope="slotData"
    >
        <slot
          :name="name"
          v-bind="slotData"
        />
    </template>
  </v-text-field>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  props: {
    debounce: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      input: debounce((e) => this.$emit('input', e), this.debounce)
    }
  },
}
</script>
