<script>
const isEmptyValue = (value) => [undefined, '', null].includes(value)

export default {
  props: {
    map: {
      type: Object
    }
  },
  methods: {
    update (mode, payload = {}) {
      let query = Object
        .entries(payload)
        .filter(([k, v]) => !isEmptyValue(v))
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
      if (this.map) {
        query = this.mapFields(query, this.map)
      }
      return this.$router[mode]({ query })
    },
    mapFields (fields, map, reverse = false) {
      return Object
        .entries(map)
        .reduce((acc, [from, to]) => {
          if (reverse) {
            acc[from] = fields[to]
          } else {
            acc[to] = fields[from]
          }
          return acc
        }, {})
    }
  },
  render () {
    const query = this.$route.query
    return this.$scopedSlots.default({
      query: this.map ? this.mapFields(query, this.map, true) : query,
      push: (payload) => this.update('push', payload),
      replace: (payload) => this.update('replace', payload)
    })
  },
}
</script>
