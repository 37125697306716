<template>
  <div class="calc">
    <div class="calc__main">
      <calc-search
        :speciality-ids="specialityIds"
        :filters="state.filters"
        :filter="filter"
      />
    </div>
    <div class="calc__aside">
      <calc-dashboard class="calc__dashboard" />
      <calc-cart />
    </div>
  </div>
</template>

<script>
import CalcCart from './CalcCart.vue'
import CalcDashboard from './CalcDashboard.vue'
import CalcSearch from './CalcSearch.vue'
import CalcConfig from '@/models/CalcConfig'
import calcMixin from './calcMixin'
import OnlyPriceFilters from '@/models/OnlyPriceFilters'

export const EVT_FILTER = 'filter'

export default {
  mixins: [calcMixin],
  props: {
    specialityIds: {
      type: Array,
      default: () => ([])
    },
    filters: {
      type: Object,
      default: () => new OnlyPriceFilters()
    },
    options: {
      type: Object,
      default: () => new CalcConfig(),
    }
  },
  data () {
    return {
      state: {
        filters: { ...this.filters }
      }
    }
  },
  watch: {
    filters: {
      deep: true,
      handler (filters) {
        if (isEqual(this.state.filters, filters))
        this.filter(filters)
      }
    }
  },
  components: {
    CalcCart,
    CalcDashboard,
    CalcSearch,
  },
  watch: {
    options: {
      deep: true,
      handler (options) {
        this.configure(options)
      }
    }
  },
  created () {
    this.configure(this.options)
  },
  methods: {
    filter (payload) {
      this.$set(this.state, 'filters', new OnlyPriceFilters({
        ...this.state.filters,
        ...payload
      }))
      this.$emit(EVT_FILTER, this.state.filters)
    }
  },
  destroyed () {
    this.reset()
  }
}
</script>

<style lang="scss" scoped>
.calc {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
}

.calc__dashboard {
  margin-bottom: 20px;
}
</style>
