<template>
  <div class="calc-person-select">
    <div class="calc-person-select__person" v-if="value">
      <div class="calc-person-select__person-content">
        <slot name="person" />
      </div>
      <div class="calc-person-select__person-actions">
        <v-btn @click="() => $emit('input', null)" icon small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
    <div
      class="calc-person-select__control"
      :style="{
        visibility: value ? 'hidden' : 'visible',
        width: value ? '0px' : '100%',
      }"
    >
      <slot name="control" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    }
  }
}
</script>

<style lang="scss" scoped>
.calc-person-select {
  display: flex;
  align-items: baseline;
  width: 100%;
  min-height: 45px;
  & > * {
    flex: 0 0 auto;
  }
}

.calc-person-select__person {
  display: flex;
  width: 100%;
  grid-gap: 12px;
  align-items: center;
  justify-content: space-between;
}
</style>
